export default {
  "首页": "首頁",
  "订单": "訂單",
  "新闻": "新聞",
  "个人中心": "個人中心",
  "模拟账户": "模擬帳戶",
  "可用资产": "可用資產",
  "入金": "入金",
  "出金": "出金",
  "账单详情": "帳單詳情",
  "重置模拟账户": "重置模擬帳戶",
  "钱包": "錢包",
  "实名认证": "實名認證",
  "邀请好友": "邀請好友",
  "修改密码": "修改密碼",
  "投诉邮箱": "投訴郵箱",
  "其它": "其它",
  "公告": "公告",
  "语言": "語言",
  "是否退出登录": "是否退出登錄",
  "复制": "複製",
  "复制成功": "複製成功",
  "发送验证码成功": "發送驗證碼成功",
  "请输入密码": "請輸入密碼",
  "请再次输入密码": "請再次輸入密碼",
  "复制邀请链接": "複製邀請鏈接",
  "上传身份": "請上傳護照/證件的正反面",
  "正面": "正面",
  "上传正面": "上傳護照/證件正面",
  "反面": "反面",
  "上传反面": "上傳護照/證件反面",
  "完善信息": "請完善個人訊息",
  "输入姓名": "請輸入您的姓名",
  "身份证号": "護照/證件號",
  "输入身份证号": "請輸入您的護照/證件號",
  "收款货币": "收款貨幣",
  "银行名称": "銀行名稱",
  "收款人账户": "收款人帳戶",
  "货币": "貨幣",
  "分店名称": "分店名稱",
  "绑定银行卡": "綁定銀行卡",
  "银行地址": "銀行地址",
  "账号名称（假名）": "帳號名稱（假名）",
  "绑定数字货币地址": "綁定數字貨幣地址",
  "余额": "餘額",
  "联系客服": "聯系客服",
  "行情": "行情",
  "其他": "其他",
  "设置": "設定",
  "收款人": "收款人",
  "添加": "添加",
  "确认": "確認",
  "取消": "取消",
  "钱包地址": "錢包地址",
  "请输入": "請輸入",
  "退出登录": "退出登錄",
  "充值数量": "充值數量",
  "提交": "提交",
  "银行卡": "銀行卡",
  "选择网络": "選擇網絡",
  "数字货币": "數字貨幣",
  "上传验证": "上傳驗證",
  "提币地址": "提幣地址",
  "数量(USD)": "數量（USD）",
  "备注": "備註",
  "手续费": "手續費",
  "预计到账数量": "預計到賬數量",
  "暂无数据": "暫無數據",
  "入金记录": "入金記錄",
  "提币记录": "提幣記錄",
  "验证码": "驗證碼",
  "邀请码": "邀請碼",
  "提示": "提示",
  "请输入验证码": "請輸入驗證碼",
  "姓名": "姓名",
  "邮箱登录": "郵箱登錄",
  "邮箱": "郵箱",
  "密码": "密碼",
  "新用户": "新用戶",
  "立即加入": "立即加入",
  "忘记密码": "忘記密碼",
  "登录": "登錄",
  "无需开户,立即创建模拟账号进行交易!": "無需開戶，立即創建模擬帳戶進行交易！",
  "电话": "電話",
  "再次输入密码": "再次輸入密碼",
  "职业": "職業",
  "年收入": "年收入",
  "注册": "註冊",
  "邮箱注册": "郵箱註冊",
  "发送": "發送",
  "重置密码": "重置密碼",
  "是否重置": "是否重置",
  "已重置": "已重置",
  "名称": "名稱",
  "注册成功": "註冊成功",
  "加载中": "載入中",
  "您还未登录，请先登录": "您尚未登錄，請先登入",
  "退出成功": "退出成功",
  "市价": "市價",
  "挂单": "掛單",
  "倍数": "倍數",
  "止盈": "止盈",
  "止损": "止損",
  "买入数量": "買入數量",
  "每手": "每手",
  "预估手续费": "預估手續費",
  "预估保证金": "預估保證金",
  "买入": "買入",
  "卖出": "賣出",
  "您的订单已确认": "您的訂單已確認",
  "订单页面": "訂單頁面",
  "盈亏": "盈虧",
  "当前保证金": "當前保證金",
  "订单详情": "訂單詳情",
  "保证金": "保證金",
  "平仓": "平倉",
  "修改": "修改",
  "手数": "手數",
  "暂无数据": "暫無數據",
  "历史": "歷史",
  "价格": "價格",
  "请输入价格": "請輸入價格",
  "手": "手",
  "搜索结果": "搜索結果",
  "搜索产品": "搜索產品",
  "持仓": "持倉",
  "语言": "語言",
  "审核中": "審核中",
  "请上传证件照正/反面": "請上傳證件照正/反面",
  "上传成功": "上傳成功",
  "上传失败": "上傳失敗",
  "提交成功": "提交成功",
  "删除": "刪除",
  "删除成功": "刪除成功",
  "提币": "提幣",
  "缺少参数或传值错误": "缺少參數或傳值錯誤",
  "操作成功": "操作成功",
  "充值金额": "充值金額",
  "类型": "類型",
  "状态": "狀態",
  "时间": "時間",
  "单位": "單位",
  "最高价": "最高價",
  "最低价": "最低價",
  "开盘价": "開盤價",
  "请注册或登录真实账号": "請註冊或登入真實帳號",
  "没有更多了": "沒有更多了",
  "提现金额": "提現金額",
  "审核通过": "審核通過",
  "审核不通过": "審核不通過",
  "重新提交": "重新提交",
  "止盈价": "止盈價",
  "止损价": "止損價",
  "总盈亏": "總盈虧",
  "撤单": "撤單",
  "成功": "成功",
  "请先进行实名认证": "請先進行實名認證",
  "下单余额不足": "下單餘額不足",
  "客服": "客服",
  "卖出(做空)止损价不能低于开仓价": "賣出(做空)止損價不能低於開倉價",
  "卖出(做多)止盈价不能高于开仓价": "賣出(做多)止盈價不能高於開倉價",
  "买入(做多)止损价不能高于开仓价": "買入(做多)止損價不能高於開倉價",
  "买入(做多)止盈价不能低于开仓价": "買入(做多)止盈價不能低於開倉價",
  "买入(做多)止盈价不能低于开仓价和现价": "買入(做多)止盈價不能低於開倉價和現價",
  "买入(做多)止损价不能高于开仓价和现价": "買入(做多)止損價不能高於開倉價和現價",
  "卖出(做空)止损价不能低于开仓价和现价": "賣出(做空)止損價不能低於開倉價和現價",
  "卖出(做多)止盈价不能高于开仓价和现价": "賣出(做多)止盈價不能高於開倉價和現價"
}
