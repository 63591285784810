export default {
  "首页": "หน้าแรก",
  "订单": "คำสั่งซื้อ",
  "新闻": "ข่าวสาร",
  "个人中心": "ศูนย์ส่วนตัว",
  "模拟账户": "บัญชีทดลอง",
  "可用资产": "ทรัพย์สินที่ใช้ได้",
  "入金": "ฝากเงิน",
  "出金": "ถอนเงิน",
  "账单详情": "รายละเอียดใบแจ้งหนี้",
  "重置模拟账户": "รีเซ็ตบัญชีทดลอง",
  "钱包": "กระเป๋าสตางค์",
  "实名认证": "การยืนยันตัวตน",
  "邀请好友": "เชิญเพื่อน",
  "修改密码": "เปลี่ยนรหัสผ่าน",
  "投诉邮箱": "อีเมลสำหรับร้องเรียน",
  "其它": "อื่นๆ",
  "公告": "ประกาศ",
  "语言": "ภาษา",
  "是否退出登录": "คุณต้องการออกจากระบบหรือไม่",
  "复制": "คัดลอก",
  "复制成功": "คัดลอกสำเร็จ",
  "发送验证码成功": "ส่งรหัสยืนยันสำเร็จ",
  "请输入密码": "กรุณากรอกรหัสผ่าน",
  "请再次输入密码": "กรุณากรอกรหัสผ่านอีกครั้ง",
  "复制邀请链接": "คัดลอกลิงค์เชิญ",
  "上传身份": "กรุณาอัพโหลดด้านหน้าและด้านหลังของพาสปอร์ต/บัตรประจำตัว",
  "正面": "ด้านหน้า",
  "上传正面": "อัพโหลดด้านหน้าของพาสปอร์ต/บัตรประจำตัว",
  "反面": "ด้านหลัง",
  "上传反面": "อัพโหลดด้านหลังของพาสปอร์ต/บัตรประจำตัว",
  "完善信息": "กรุณากรอกข้อมูลส่วนบุคคล",
  "输入姓名": "กรุณากรอกชื่อของคุณ",
  "身份证号": "หมายเลขพาสปอร์ต/บัตรประจำตัว",
  "输入身份证号": "กรุณากรอกหมายเลขพาสปอร์ต/บัตรประจำตัว",
  "收款货币": "สกุลเงินที่รับ",
  "银行名称": "ชื่อธนาคาร",
  "收款人账户": "บัญชีผู้รับ",
  "货币": "สกุลเงิน",
  "分店名称": "ชื่อสาขา",
  "绑定银行卡": "ผูกบัญชีธนาคาร",
  "银行地址": "ที่อยู่ธนาคาร",
  "账号名称（假名）": "ชื่อบัญชี (ชื่อปลอม)",
  "绑定数字货币地址": "ผูกที่อยู่สกุลเงินดิจิทัล",
  "余额": "ยอดเงิน",
  "联系客服": "ติดต่อฝ่ายบริการลูกค้า",
  "行情": "ราคาตลาด",
  "其他": "อื่นๆ",
  "设置": "การตั้งค่า",
  "收款人": "ผู้รับ",
  "添加": "เพิ่ม",
  "确认": "ยืนยัน",
  "取消": "ยกเลิก",
  "钱包地址": "ที่อยู่กระเป๋าสตางค์",
  "请输入": "กรุณากรอก",
  "退出登录": "ออกจากระบบ",
  "充值数量": "จำนวนเติมเงิน",
  "提交": "ยืนยัน",
  "银行卡": "บัตรธนาคาร",
  "选择网络": "เลือกเครือข่าย",
  "数字货币": "สกุลเงินดิจิทัล",
  "上传验证": "อัพโหลดการตรวจสอบ",
  "提币地址": "ที่อยู่ถอนเงิน",
  "数量(USD)": "จำนวน (USD)",
  "备注": "หมายเหตุ",
  "手续费": "ค่าธรรมเนียม",
  "预计到账数量": "จำนวนที่คาดว่าจะถึง",
  "暂无数据": "ยังไม่มีข้อมูล",
  "入金记录": "บันทึกการฝากเงิน",
  "提币记录": "บันทึกการถอนเงิน",
  "验证码": "รหัสยืนยัน",
  "邀请码": "รหัสเชิญ",
  "提示": "คำแนะนำ",
  "请输入验证码": "กรุณากรอกรหัสยืนยัน",
  "姓名": "ชื่อ",
  "邮箱登录": "เข้าสู่ระบบด้วยอีเมล",
  "邮箱": "อีเมล",
  "密码": "รหัสผ่าน",
  "新用户": "ผู้ใช้ใหม่",
  "立即加入": "เข้าร่วมตอนนี้",
  "忘记密码": "ลืมรหัสผ่าน",
  "登录": "เข้าสู่ระบบ",
  "无需开户,立即创建模拟账号进行交易!": "ไม่ต้องเปิดบัญชี, สร้างบัญชีทดลองเพื่อทำการซื้อขายได้ทันที!",
  "电话": "โทรศัพท์",
  "再次输入密码": "กรอกรหัสผ่านอีกครั้ง",
  "职业": "อาชีพ",
  "年收入": "รายได้ประจำปี",
  "注册": "ลงทะเบียน",
  "邮箱注册": "ลงทะเบียนด้วยอีเมล",
  "发送": "ส่ง",
  "重置密码": "รีเซ็ตรหัสผ่าน",
  "是否重置": "คุณต้องการรีเซ็ตรหัสผ่านหรือไม่",
  "已重置": "รีเซ็ตแล้ว",
  "名称": "ชื่อ",
  "注册成功": "ลงทะเบียนสำเร็จ",
  "加载中": "กำลังโหลด",
  "您还未登录，请先登录": "คุณยังไม่ได้เข้าสู่ระบบ, กรุณาเข้าสู่ระบบก่อน",
  "退出成功": "ออกจากระบบสำเร็จ",
  "市价": "ราคาตลาด",
  "挂单": "คำสั่งซื้อที่รอ",
  "倍数": "ตัวคูณ",
  "止盈": "ทำกำไร",
  "止损": "หยุดขาดทุน",
  "买入数量": "จำนวนซื้อ",
  "每手": "ต่อสัญญา",
  "预估手续费": "ค่าธรรมเนียมที่คาดการณ์",
  "预估保证金": "มาร์จิ้นที่คาดการณ์",
  "买入": "ซื้อ",
  "卖出": "ขาย",
  "您的订单已确认": "คำสั่งของคุณได้รับการยืนยันแล้ว",
  "订单页面": "หน้าคำสั่งซื้อ",
  "盈亏": "กำไร/ขาดทุน",
  "当前保证金": "มาร์จิ้นปัจจุบัน",
  "订单详情": "รายละเอียดคำสั่งซื้อ",
  "保证金": "มาร์จิ้น",
  "平仓": "ปิดตำแหน่ง",
  "修改": "แก้ไข",
  "手数": "จำนวนสัญญา",
  "暂无数据": "ไม่มีข้อมูล",
  "历史": "ประวัติ",
  "价格": "ราคา",
  "请输入价格": "กรุณากรอกราคา",
  "手": "สัญญา",
  "搜索结果": "ผลการค้นหา",
  "搜索产品": "ค้นหาผลิตภัณฑ์",
  "持仓": "ตำแหน่งที่เปิด",
  "语言": "ภาษา",
  "审核中": "กำลังตรวจสอบ",
  "请上传证件照正/反面": "กรุณาอัพโหลดด้านหน้าและด้านหลังของเอกสาร",
  "上传成功": "อัพโหลดสำเร็จ",
  "上传失败": "อัพโหลดล้มเหลว",
  "提交成功": "ส่งสำเร็จ",
  "删除": "ลบ",
  "删除成功": "ลบสำเร็จ",
  "提币": "ถอนคริปโต",
  "缺少参数或传值错误": "ขาดพารามิเตอร์หรือค่าผิดพลาด",
  "操作成功": "ดำเนินการสำเร็จ",
  "充值金额": "จำนวนฝากเงิน",
  "类型": "ประเภท",
  "状态": "สถานะ",
  "时间": "เวลา",
  "单位": "หน่วย",
  "最高价": "ราคาสูงสุด",
  "最低价": "ราคาต่ำสุด",
  "开盘价": "ราคาตลาดเปิด",
  "请注册或登录真实账号": "กรุณาลงทะเบียนหรือเข้าสู่ระบบด้วยบัญชีจริง",
  "没有更多了": "ไม่มีข้อมูลเพิ่มเติม",
  "提现金额": "จำนวนถอนเงิน",
  "审核通过": "การตรวจสอบผ่าน",
  "审核不通过": "การตรวจสอบไม่ผ่าน",
  "重新提交": "ส่งใหม่",
  "止盈价": "ราคาทำกำไร",
  "止损价": "ราคาหยุดขาดทุน",
  "总盈亏": "กำไร/ขาดทุนรวม",
  "撤单": "ยกเลิกคำสั่ง",
  "成功": "สำเร็จ",
  "请先进行实名认证": "กรุณาทำการยืนยันตัวตนก่อน",
  "下单余额不足": "ยอดเงินไม่เพียงพอในการสั่งซื้อ",
  "客服": "ฝ่ายบริการลูกค้า",
  "卖出(做空)止损价不能低于开仓价": "ราคาหยุดขาดทุนในการขาย (short) ไม่สามารถต่ำกว่าราคาที่เปิดตำแหน่ง",
  "卖出(做多)止盈价不能高于开仓价": "ราคาทำกำไรในการขาย (long) ไม่สามารถสูงกว่าราคาที่เปิดตำแหน่ง",
  "买入(做多)止损价不能高于开仓价": "ราคาหยุดขาดทุนในการซื้อ (long) ไม่สามารถสูงกว่าราคาที่เปิดตำแง่ง",
  "买入(做多)止盈价不能低于开仓价": "ราคาทำกำไรในการซื้อ (long) ไม่สามารถต่ำกว่าราคาที่เปิดตำแหน่ง",
  "买入(做多)止盈价不能低于开仓价和现价": "ราคาทำกำไรในการซื้อ (long) ไม่สามารถต่ำกว่าราคาที่เปิดตำแหน่งและราคาปัจจุบัน",
  "买入(做多)止损价不能高于开仓价和现价": "ราคาหยุดขาดทุนในการซื้อ (long) ไม่สามารถสูงกว่าราคาที่เปิดตำแหน่งและราคาปัจจุบัน",
  "卖出(做空)止损价不能低于开仓价和现价": "ราคาหยุดขาดทุนในการขาย (short) ไม่สามารถต่ำกว่าราคาที่เปิดตำแหน่งและราคาปัจจุบัน",
  "卖出(做多)止盈价不能高于开仓价和现价": "ราคาขาย (ซื้อ) ขายทำกำไรต้องไม่สูงกว่าราคาเปิดและราคาปัจจุบัน"
}
