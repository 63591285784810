export default {
  "首页": "Home",
  "订单": "Ordini",
  "新闻": "Notizie",
  "个人中心": "Centro Personale",
  "模拟账户": "Account Demo",
  "可用资产": "Attività Disponibili",
  "入金": "Deposito",
  "出金": "Prelievo",
  "账单详情": "Dettagli Fattura",
  "重置模拟账户": "Ripristina Account Demo",
  "钱包": "Portafoglio",
  "实名认证": "Verifica Identità",
  "邀请好友": "Invita Amici",
  "修改密码": "Modifica Password",
  "投诉邮箱": "Email Reclami",
  "其它": "Altro",
  "公告": "Annunci",
  "语言": "Lingua",
  "是否退出登录": "Vuoi uscire?",
  "复制": "Copia",
  "复制成功": "Copia riuscita",
  "发送验证码成功": "Codice di verifica inviato con successo",
  "请输入密码": "Inserisci la password",
  "请再次输入密码": "Inserisci di nuovo la password",
  "复制邀请链接": "Copia il link di invito",
  "上传身份": "Carica il fronte e il retro del passaporto/documento",
  "正面": "Fronte",
  "上传正面": "Carica il fronte del passaporto/documento",
  "反面": "Retro",
  "上传反面": "Carica il retro del passaporto/documento",
  "完善信息": "Completa le informazioni personali",
  "输入姓名": "Inserisci il tuo nome",
  "身份证号": "Numero di passaporto/documento",
  "输入身份证号": "Inserisci il numero del passaporto/documento",
  "收款货币": "Valuta di ricevimento",
  "银行名称": "Nome della banca",
  "收款人账户": "Conto del destinatario",
  "货币": "Valuta",
  "分店名称": "Nome della filiale",
  "绑定银行卡": "Collega la carta bancaria",
  "银行地址": "Indirizzo della banca",
  "账号名称（假名）": "Nome account (nome fittizio)",
  "绑定数字货币地址": "Collega l'indirizzo di criptovaluta",
  "余额": "Saldo",
  "联系客服": "Contatta il supporto clienti",
  "行情": "Mercato",
  "其他": "Altro",
  "设置": "Impostazioni",
  "收款人": "Destinatario",
  "添加": "Aggiungi",
  "确认": "Conferma",
  "取消": "Annulla",
  "钱包地址": "Indirizzo del portafoglio",
  "请输入": "Inserisci",
  "退出登录": "Esci",
  "充值数量": "Quantità di deposito",
  "提交": "Invia",
  "银行卡": "Carta bancaria",
  "选择网络": "Seleziona la rete",
  "数字货币": "Criptovaluta",
  "上传验证": "Carica verifica",
  "提币地址": "Indirizzo di prelievo",
  "数量(USD)": "Quantità (USD)",
  "备注": "Nota",
  "手续费": "Commissione",
  "预计到账数量": "Quantità prevista",
  "暂无数据": "Nessun dato disponibile",
  "入金记录": "Registro depositi",
  "提币记录": "Registro prelievi",
  "验证码": "Codice di verifica",
  "邀请码": "Codice di invito",
  "提示": "Avviso",
  "请输入验证码": "Inserisci il codice di verifica",
  "姓名": "Nome",
  "邮箱登录": "Login con email",
  "邮箱": "Email",
  "密码": "Password",
  "新用户": "Nuovo utente",
  "立即加入": "Unisciti ora",
  "忘记密码": "Password dimenticata",
  "登录": "Accedi",
  "无需开户,立即创建模拟账号进行交易!": "Non è necessario aprire un conto, crea subito un account demo per fare trading!",
  "电话": "Telefono",
  "再次输入密码": "Inserisci la password di nuovo",
  "职业": "Professione",
  "年收入": "Reddito annuale",
  "注册": "Registrati",
  "邮箱注册": "Registrazione con email",
  "发送": "Invia",
  "重置密码": "Reimposta la password",
  "是否重置": "Vuoi reimpostare?",
  "已重置": "Reimpostato",
  "名称": "Nome",
  "注册成功": "Registrazione riuscita",
  "加载中": "Caricamento in corso",
  "您还未登录，请先登录": "Non hai effettuato l'accesso, effettua prima il login",
  "退出成功": "Uscita riuscita",
  "市价": "Prezzo di mercato",
  "挂单": "Ordine pendente",
  "倍数": "Moltiplicatore",
  "止盈": "Take profit",
  "止损": "Stop loss",
  "买入数量": "Quantità di acquisto",
  "每手": "Per lotto",
  "预估手续费": "Commissione stimata",
  "预估保证金": "Margine stimato",
  "买入": "Acquista",
  "卖出": "Vendi",
  "您的订单已确认": "Il tuo ordine è stato confermato",
  "订单页面": "Pagina ordine",
  "盈亏": "Profitto e perdita",
  "当前保证金": "Margine attuale",
  "订单详情": "Dettagli ordine",
  "保证金": "Margine",
  "平仓": "Chiudi posizione",
  "修改": "Modifica",
  "手数": "Lotti",
  "暂无数据": "Nessun dato disponibile",
  "历史": "Storico",
  "价格": "Prezzo",
  "请输入价格": "Inserisci il prezzo",
  "手": "Lotti",
  "搜索结果": "Risultati di ricerca",
  "搜索产品": "Cerca prodotto",
  "持仓": "Posizioni",
  "语言": "Lingua",
  "审核中": "In revisione",
  "请上传证件照正/反面": "Carica la foto del fronte e del retro del documento",
  "上传成功": "Caricamento riuscito",
  "上传失败": "Caricamento fallito",
  "提交成功": "Invio riuscito",
  "删除": "Elimina",
  "删除成功": "Eliminato con successo",
  "提币": "Prelievo",
  "缺少参数或传值错误": "Parametro mancante o valore errato",
  "操作成功": "Operazione riuscita",
  "充值金额": "Importo del deposito",
  "类型": "Tipo",
  "状态": "Stato",
  "时间": "Tempo",
  "单位": "Unità",
  "最高价": "Prezzo massimo",
  "最低价": "Prezzo minimo",
  "开盘价": "Prezzo di apertura",
  "请注册或登录真实账号": "Si prega di registrarsi o accedere con un account reale",
  "没有更多了": "Non ci sono più dati",
  "提现金额": "Importo del prelievo",
  "审核通过": "Revisione approvata",
  "审核不通过": "Revisione non approvata",
  "重新提交": "Invia di nuovo",
  "止盈价": "Prezzo take profit",
  "止损价": "Prezzo stop loss",
  "总盈亏": "Profitto/perdita totale",
  "撤单": "Annulla ordine",
  "成功": "Successo",
  "请先进行实名认证": "Si prega di effettuare la verifica dell'identità prima",
  "下单余额不足": "Saldo insufficiente per effettuare l'ordine",
  "客服": "Assistenza clienti",
  "卖出(做空)止损价不能低于开仓价": "Il prezzo stop loss per vendere (short) non può essere inferiore al prezzo di apertura",
  "卖出(做多)止盈价不能高于开仓价": "Il prezzo take profit per vendere (long) non può essere superiore al prezzo di apertura",
  "买入(做多)止损价不能高于开仓价": "Il prezzo stop loss per comprare (long) non può essere superiore al prezzo di apertura",
  "买入(做多)止盈价不能低于开仓价": "Il prezzo take profit per comprare (long) non può essere inferiore al prezzo di apertura",
  "买入(做多)止盈价不能低于开仓价和现价": "Il prezzo take profit per comprare (long) non può essere inferiore al prezzo di apertura e al prezzo attuale",
  "买入(做多)止损价不能高于开仓价和现价": "Il prezzo stop loss per comprare (long) non può essere superiore al prezzo di apertura e al prezzo attuale",
  "卖出(做空)止损价不能低于开仓价和现价": "Il prezzo stop loss per vendere (short) non può essere inferiore al prezzo di apertura e al prezzo attuale",
  "卖出(做多)止盈价不能高于开仓价和现价": "Il prezzo take profit per vendere (long) non può essere superiore al prezzo di apertura e al prezzo attuale"
}
