export default {
  "首页": "Beranda",
  "订单": "Pesanan",
  "新闻": "Berita",
  "个人中心": "Pusat Pribadi",
  "模拟账户": "Akun Demo",
  "可用资产": "Aset Tersedia",
  "入金": "Setoran",
  "出金": "Penarikan",
  "账单详情": "Rincian Tagihan",
  "重置模拟账户": "Reset Akun Demo",
  "钱包": "Dompet",
  "实名认证": "Verifikasi Identitas",
  "邀请好友": "Undang Teman",
  "修改密码": "Ubah Kata Sandi",
  "投诉邮箱": "Email Keluhan",
  "其它": "Lainnya",
  "公告": "Pengumuman",
  "语言": "Bahasa",
  "是否退出登录": "Apakah Anda ingin keluar?",
  "复制": "Salin",
  "复制成功": "Salin Berhasil",
  "发送验证码成功": "Kode Verifikasi Berhasil Dikirim",
  "请输入密码": "Silakan Masukkan Kata Sandi",
  "请再次输入密码": "Silakan Masukkan Kata Sandi Lagi",
  "复制邀请链接": "Salin Tautan Undangan",
  "上传身份": "Silakan Unggah Foto Depan dan Belakang KTP/Paspor Anda",
  "正面": "Depan",
  "上传正面": "Unggah Foto Depan KTP/Paspor",
  "反面": "Belakang",
  "上传反面": "Unggah Foto Belakang KTP/Paspor",
  "完善信息": "Silakan Lengkapi Informasi Pribadi Anda",
  "输入姓名": "Silakan Masukkan Nama Anda",
  "身份证号": "Nomor KTP/Paspor",
  "输入身份证号": "Silakan Masukkan Nomor KTP/Paspor Anda",
  "收款货币": "Mata Uang Penerimaan",
  "银行名称": "Nama Bank",
  "收款人账户": "Rekening Penerima",
  "货币": "Mata Uang",
  "分店名称": "Nama Cabang",
  "绑定银行卡": "Hubungkan Kartu Bank",
  "银行地址": "Alamat Bank",
  "账号名称（假名）": "Nama Akun (Nama Alias)",
  "绑定数字货币地址": "Hubungkan Alamat Cryptocurrency",
  "余额": "Saldo",
  "联系客服": "Hubungi Layanan Pelanggan",
  "行情": "Pasar",
  "其他": "Lainnya",
  "设置": "Pengaturan",
  "收款人": "Penerima",
  "添加": "Tambah",
  "确认": "Konfirmasi",
  "取消": "Batal",
  "钱包地址": "Alamat Dompet",
  "请输入": "Silakan Masukkan",
  "退出登录": "Keluar",
  "充值数量": "Jumlah Setoran",
  "提交": "Kirim",
  "银行卡": "Kartu Bank",
  "选择网络": "Pilih Jaringan",
  "数字货币": "Cryptocurrency",
  "上传验证": "Unggah Verifikasi",
  "提币地址": "Alamat Penarikan",
  "数量(USD)": "Jumlah (USD)",
  "备注": "Catatan",
  "手续费": "Biaya Transaksi",
  "预计到账数量": "Perkiraan Jumlah Yang Masuk",
  "暂无数据": "Tidak Ada Data",
  "入金记录": "Rekaman Setoran",
  "提币记录": "Rekaman Penarikan",
  "验证码": "Kode Verifikasi",
  "邀请码": "Kode Undangan",
  "提示": "Peringatan",
  "请输入验证码": "Silakan Masukkan Kode Verifikasi",
  "姓名": "Nama",
  "邮箱登录": "Masuk dengan Email",
  "邮箱": "Email",
  "密码": "Kata Sandi",
  "新用户": "Pengguna Baru",
  "立即加入": "Gabung Sekarang",
  "忘记密码": "Lupa Kata Sandi",
  "登录": "Masuk",
  "无需开户,立即创建模拟账号进行交易!": "Tidak perlu membuka akun, buat akun demo sekarang dan mulai trading!",
  "电话": "Telepon",
  "再次输入密码": "Masukkan Kata Sandi Lagi",
  "职业": "Pekerjaan",
  "年收入": "Pendapatan Tahunan",
  "注册": "Daftar",
  "邮箱注册": "Daftar dengan Email",
  "发送": "Kirim",
  "重置密码": "Reset Kata Sandi",
  "是否重置": "Apakah Anda ingin mereset?",
  "已重置": "Telah Direset",
  "名称": "Nama",
  "注册成功": "Pendaftaran Berhasil",
  "加载中": "Memuat",
  "您还未登录，请先登录": "Anda belum login, silakan login terlebih dahulu",
  "退出成功": "Keluar Berhasil",
  "市价": "Harga Pasar",
  "挂单": "Pesanan Tertunda",
  "倍数": "Kali",
  "止盈": "Ambil Untung",
  "止损": "Hentikan Kerugian",
  "买入数量": "Jumlah Pembelian",
  "每手": "Per Lot",
  "预估手续费": "Perkiraan Biaya Transaksi",
  "预估保证金": "Perkiraan Margin",
  "买入": "Beli",
  "卖出": "Jual",
  "您的订单已确认": "Pesanan Anda Telah Dikonfirmasi",
  "订单页面": "Halaman Pesanan",
  "盈亏": "Keuntungan dan Kerugian",
  "当前保证金": "Margin Saat Ini",
  "订单详情": "Rincian Pesanan",
  "保证金": "Margin",
  "平仓": "Tutup Posisi",
  "修改": "Ubah",
  "手数": "Jumlah Lot",
  "暂无数据": "Tidak Ada Data",
  "历史": "Sejarah",
  "价格": "Harga",
  "请输入价格": "Silakan Masukkan Harga",
  "手": "Lot",
  "搜索结果": "Hasil Pencarian",
  "搜索产品": "Cari Produk",
  "持仓": "Posisi",
  "语言": "Bahasa",
  "审核中": "Sedang Ditinjau",
  "请上传证件照正/反面": "Silakan Unggah Foto Depan dan Belakang KTP/Paspor Anda",
  "上传成功": "Berhasil Diunggah",
  "上传失败": "Gagal Diunggah",
  "提交成功": "Berhasil Dikirim",
  "删除": "Hapus",
  "删除成功": "Berhasil Dihapus",
  "提币": "Penarikan",
  "缺少参数或传值错误": "Parameter Hilang atau Nilai Salah",
  "操作成功": "Operasi Berhasil",
  "充值金额": "Jumlah Setoran",
  "类型": "Jenis",
  "状态": "Status",
  "时间": "Waktu",
  "单位": "Satuan",
  "最高价": "Harga Tertinggi",
  "最低价": "Harga Terendah",
  "开盘价": "Harga Pembukaan",
  "请注册或登录真实账号": "Silakan Daftar atau Masuk ke Akun Asli",
  "没有更多了": "Tidak Ada Lagi",
  "提现金额": "Jumlah Penarikan",
  "审核通过": "Tinjauan Disetujui",
  "审核不通过": "Tinjauan Ditolak",
  "重新提交": "Kirim Ulang",
  "止盈价": "Harga Ambil Untung",
  "止损价": "Harga Hentikan Kerugian",
  "总盈亏": "Total Keuntungan/Rugi",
  "撤单": "Batalkan Pesanan",
  "成功": "Sukses",
  "请先进行实名认证": "Silakan Lakukan Verifikasi Identitas Terlebih Dahulu",
  "下单余额不足": "Saldo Tidak Cukup untuk Memesan",
  "客服": "Layanan Pelanggan",
  "卖出(做空)止损价不能低于开仓价": "Harga Stop Loss untuk Jual (Short) Tidak Bisa Lebih Rendah dari Harga Pembukaan",
  "卖出(做多)止盈价不能高于开仓价": "Harga Take Profit untuk Jual (Long) Tidak Bisa Lebih Tinggi dari Harga Pembukaan",
  "买入(做多)止损价不能高于开仓价": "Harga Stop Loss untuk Beli (Long) Tidak Bisa Lebih Tinggi dari Harga Pembukaan",
  "买入(做多)止盈价不能低于开仓价": "Harga Take Profit untuk Beli (Long) Tidak Bisa Lebih Rendah dari Harga Pembukaan",
  "买入(做多)止盈价不能低于开仓价和现价": "Harga Take Profit untuk Beli (Long) Tidak Bisa Lebih Rendah dari Harga Pembukaan dan Harga Saat Ini",
  "买入(做多)止损价不能高于开仓价和现价": "Harga Stop Loss untuk Beli (Long) Tidak Bisa Lebih Tinggi dari Harga Pembukaan dan Harga Saat Ini",
  "卖出(做空)止损价不能低于开仓价和现价": "Harga Stop Loss untuk Jual (Short) Tidak Bisa Lebih Rendah dari Harga Pembukaan dan Harga Saat Ini",
  "卖出(做多)止盈价不能高于开仓价和现价": "Harga Take Profit untuk Jual (Long) Tidak Bisa Lebih Tinggi dari Harga Pembukaan dan Harga Saat Ini"
}
