export default {
  "首页": "Startseite",
  "订单": "Bestellung",
  "新闻": "Nachrichten",
  "个人中心": "Benutzerzentrum",
  "模拟账户": "Demokonto",
  "可用资产": "Verfügbare Vermögenswerte",
  "入金": "Einzahlung",
  "出金": "Auszahlung",
  "账单详情": "Rechnungsdetails",
  "重置模拟账户": "Demokonto zurücksetzen",
  "钱包": "Brieftasche",
  "实名认证": "Identitätsverifizierung",
  "邀请好友": "Freunde einladen",
  "修改密码": "Passwort ändern",
  "投诉邮箱": "Beschwerde-E-Mail",
  "其它": "Sonstiges",
  "公告": "Ankündigungen",
  "语言": "Sprache",
  "是否退出登录": "Möchten Sie sich abmelden?",
  "复制": "Kopieren",
  "复制成功": "Erfolgreich kopiert",
  "发送验证码成功": "Bestätigungscode erfolgreich gesendet",
  "请输入密码": "Bitte Passwort eingeben",
  "请再次输入密码": "Bitte Passwort erneut eingeben",
  "复制邀请链接": "Einladungslink kopieren",
  "上传身份": "Bitte Vorder- und Rückseite des Ausweises hochladen",
  "正面": "Vorderseite",
  "上传正面": "Vorderseite des Ausweises hochladen",
  "反面": "Rückseite",
  "上传反面": "Rückseite des Ausweises hochladen",
  "完善信息": "Bitte persönliche Informationen vervollständigen",
  "输入姓名": "Bitte Name eingeben",
  "身份证号": "Ausweisnummer",
  "输入身份证号": "Bitte Ausweisnummer eingeben",
  "收款货币": "Empfangswährung",
  "银行名称": "Bankname",
  "收款人账户": "Empfängerkonto",
  "货币": "Währung",
  "分店名称": "Filialname",
  "绑定银行卡": "Bankkarte verknüpfen",
  "银行地址": "Bankadresse",
  "账号名称（假名）": "Kontoname (Alias)",
  "绑定数字货币地址": "Krypto-Adresse verknüpfen",
  "余额": "Saldo",
  "联系客服": "Kundendienst kontaktieren",
  "行情": "Markt",
  "其他": "Sonstiges",
  "设置": "Einstellungen",
  "收款人": "Empfänger",
  "添加": "Hinzufügen",
  "确认": "Bestätigen",
  "取消": "Abbrechen",
  "钱包地址": "Wallet-Adresse",
  "请输入": "Bitte eingeben",
  "退出登录": "Abmelden",
  "充值数量": "Einzahlungsmenge",
  "提交": "Einreichen",
  "银行卡": "Bankkarte",
  "选择网络": "Netzwerk auswählen",
  "数字货币": "Kryptowährung",
  "上传验证": "Verifizierung hochladen",
  "提币地址": "Auszahlungsadresse",
  "数量(USD)": "Menge (USD)",
  "备注": "Bemerkungen",
  "手续费": "Gebühr",
  "预计到账数量": "Geschätzter Betrag",
  "暂无数据": "Keine Daten verfügbar",
  "入金记录": "Einzahlungsverlauf",
  "提币记录": "Auszahlungsverlauf",
  "验证码": "Bestätigungscode",
  "邀请码": "Einladungscode",
  "提示": "Hinweis",
  "请输入验证码": "Bitte Bestätigungscode eingeben",
  "姓名": "Name",
  "邮箱登录": "E-Mail-Login",
  "邮箱": "E-Mail",
  "密码": "Passwort",
  "新用户": "Neuer Benutzer",
  "立即加入": "Jetzt beitreten",
  "忘记密码": "Passwort vergessen",
  "登录": "Anmelden",
  "无需开户,立即创建模拟账号进行交易!": "Kein Konto erforderlich, erstellen Sie sofort ein Demokonto und handeln Sie!",
  "电话": "Telefon",
  "再次输入密码": "Passwort erneut eingeben",
  "职业": "Beruf",
  "年收入": "Jahreseinkommen",
  "注册": "Registrieren",
  "邮箱注册": "E-Mail-Registrierung",
  "发送": "Senden",
  "重置密码": "Passwort zurücksetzen",
  "是否重置": "Möchten Sie zurücksetzen?",
  "已重置": "Zurückgesetzt",
  "名称": "Name",
  "注册成功": "Registrierung erfolgreich",
  "加载中": "Lädt...",
  "您还未登录，请先登录": "Sie sind nicht eingeloggt, bitte zuerst einloggen",
  "退出成功": "Erfolgreich abgemeldet",
  "市价": "Marktpreis",
  "挂单": "Limit-Order",
  "倍数": "Hebel",
  "止盈": "Take-Profit",
  "止损": "Stop-Loss",
  "买入数量": "Kaufmenge",
  "每手": "Pro Lot",
  "预估手续费": "Geschätzte Gebühr",
  "预估保证金": "Geschätzte Margin",
  "买入": "Kaufen",
  "卖出": "Verkaufen",
  "您的订单已确认": "Ihre Bestellung wurde bestätigt",
  "订单页面": "Bestellseite",
  "盈亏": "Gewinn/Verlust",
  "当前保证金": "Aktuelle Margin",
  "订单详情": "Bestelldetails",
  "保证金": "Margin",
  "平仓": "Position schließen",
  "修改": "Bearbeiten",
  "手数": "Lots",
  "暂无数据": "Keine Daten verfügbar",
  "历史": "Historie",
  "价格": "Preis",
  "请输入价格": "Bitte Preis eingeben",
  "手": "Lot",
  "搜索结果": "Suchergebnisse",
  "搜索产品": "Produkte suchen",
  "持仓": "Offene Positionen",
  "语言": "Sprache",
  "审核中": "Wird überprüft",
  "请上传证件照正/反面": "Bitte Vorder- und Rückseite des Dokuments hochladen",
  "上传成功": "Erfolgreich hochgeladen",
  "上传失败": "Hochladen fehlgeschlagen",
  "提交成功": "Erfolgreich eingereicht",
  "删除": "Löschen",
  "删除成功": "Erfolgreich gelöscht",
  "提币": "Krypto abheben",
  "缺少参数或传值错误": "Parameter fehlen oder falsche Werte",
  "操作成功": "Erfolgreich ausgeführt",
  "充值金额": "Einzahlungsbetrag",
  "类型": "Typ",
  "状态": "Status",
  "时间": "Zeit",
  "单位": "Einheit",
  "最高价": "Höchstpreis",
  "最低价": "Tiefstpreis",
  "开盘价": "Eröffnungskurs",
  "请注册或登录真实账号": "Bitte registrieren oder mit einem echten Konto anmelden",
  "没有更多了": "Keine weiteren Daten",
  "提现金额": "Auszahlungsbetrag",
  "审核通过": "Genehmigt",
  "审核不通过": "Nicht genehmigt",
  "重新提交": "Erneut einreichen",
  "止盈价": "Take-Profit-Preis",
  "止损价": "Stop-Loss-Preis",
  "总盈亏": "Gesamter Gewinn/Verlust",
  "撤单": "Bestellung stornieren",
  "成功": "Erfolgreich",
  "请先进行实名认证": "Bitte zuerst die Identität verifizieren",
  "下单余额不足": "Unzureichendes Guthaben",
  "客服": "Kundendienst",
  "卖出(做空)止损价不能低于开仓价": "Stop-Loss für Verkauf (Short) darf nicht unter dem Eröffnungspreis liegen",
  "卖出(做多)止盈价不能高于开仓价": "Take-Profit für Verkauf (Long) darf nicht über dem Eröffnungspreis liegen",
  "买入(做多)止损价不能高于开仓价": "Stop-Loss für Kauf (Long) darf nicht über dem Eröffnungspreis liegen",
  "买入(做多)止盈价不能低于开仓价": "Take-Profit für Kauf (Long) darf nicht unter dem Eröffnungspreis liegen",
  "买入(做多)止盈价不能低于开仓价和现价": "Take-Profit für Kauf (Long) darf nicht unter dem Eröffnungspreis und dem aktuellen Preis liegen",
  "买入(做多)止损价不能高于开仓价和现价": "Stop-Loss für Kauf (Long) darf nicht über dem Eröffnungspreis und dem aktuellen Preis liegen",
  "卖出(做空)止损价不能低于开仓价和现价": "Stop-Loss für Verkauf (Short) darf nicht unter dem Eröffnungspreis und dem aktuellen Preis liegen",
  "卖出(做多)止盈价不能高于开仓价和现价": "Take-Profit für Verkauf (Long) darf nicht über dem Eröffnungspreis und dem aktuellen Preis liegen",
}
