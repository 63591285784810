export default {
  "首页": "الصفحة الرئيسية",
  "订单": "الطلبات",
  "新闻": "الأخبار",
  "个人中心": "مركز المستخدم",
  "模拟账户": "حساب تجريبي",
  "可用资产": "الأصول المتاحة",
  "入金": "إيداع",
  "出金": "سحب",
  "账单详情": "تفاصيل الفاتورة",
  "重置模拟账户": "إعادة تعيين الحساب التجريبي",
  "钱包": "المحفظة",
  "实名认证": "التحقق من الهوية",
  "邀请好友": "دعوة الأصدقاء",
  "修改密码": "تغيير كلمة المرور",
  "投诉邮箱": "بريد الشكاوى",
  "其它": "أخرى",
  "公告": "الإعلانات",
  "语言": "اللغة",
  "是否退出登录": "هل تريد تسجيل الخروج؟",
  "复制": "نسخ",
  "复制成功": "تم النسخ بنجاح",
  "发送验证码成功": "تم إرسال رمز التحقق بنجاح",
  "请输入密码": "يرجى إدخال كلمة المرور",
  "请再次输入密码": "يرجى إدخال كلمة المرور مرة أخرى",
  "复制邀请链接": "نسخ رابط الدعوة",
  "上传身份": "يرجى تحميل صورة الهوية (الوجهين)",
  "正面": "الوجه الأمامي",
  "上传正面": "تحميل الوجه الأمامي للهوية",
  "反面": "الوجه الخلفي",
  "上传反面": "تحميل الوجه الخلفي للهوية",
  "完善信息": "يرجى استكمال البيانات الشخصية",
  "输入姓名": "يرجى إدخال الاسم",
  "身份证号": "رقم الهوية",
  "输入身份证号": "يرجى إدخال رقم الهوية",
  "收款货币": "عملة الاستلام",
  "银行名称": "اسم البنك",
  "收款人账户": "حساب المستلم",
  "货币": "العملة",
  "分店名称": "اسم الفرع",
  "绑定银行卡": "ربط البطاقة البنكية",
  "银行地址": "عنوان البنك",
  "账号名称（假名）": "اسم الحساب (اسم مستعار)",
  "绑定数字货币地址": "ربط عنوان العملة الرقمية",
  "余额": "الرصيد",
  "联系客服": "التواصل مع خدمة العملاء",
  "行情": "السوق",
  "其他": "أخرى",
  "设置": "الإعدادات",
  "收款人": "المستلم",
  "添加": "إضافة",
  "确认": "تأكيد",
  "取消": "إلغاء",
  "钱包地址": "عنوان المحفظة",
  "请输入": "يرجى الإدخال",
  "退出登录": "تسجيل الخروج",
  "充值数量": "كمية الإيداع",
  "提交": "إرسال",
  "银行卡": "البطاقة البنكية",
  "选择网络": "اختر الشبكة",
  "数字货币": "العملة الرقمية",
  "上传验证": "تحميل التحقق",
  "提币地址": "عنوان السحب",
  "数量(USD)": "الكمية (USD)",
  "备注": "ملاحظات",
  "手续费": "الرسوم",
  "预计到账数量": "الكمية المتوقع استلامها",
  "暂无数据": "لا توجد بيانات",
  "入金记录": "سجل الإيداع",
  "提币记录": "سجل السحب",
  "验证码": "رمز التحقق",
  "邀请码": "رمز الدعوة",
  "提示": "تلميح",
  "请输入验证码": "يرجى إدخال رمز التحقق",
  "姓名": "الاسم",
  "邮箱登录": "تسجيل الدخول بالبريد الإلكتروني",
  "邮箱": "البريد الإلكتروني",
  "密码": "كلمة المرور",
  "新用户": "مستخدم جديد",
  "立即加入": "انضم الآن",
  "忘记密码": "نسيت كلمة المرور",
  "登录": "تسجيل الدخول",
  "无需开户,立即创建模拟账号进行交易!": "لا حاجة لفتح حساب، أنشئ حسابًا تجريبيًا وابدأ التداول الآن!",
  "电话": "الهاتف",
  "再次输入密码": "إدخال كلمة المرور مرة أخرى",
  "职业": "المهنة",
  "年收入": "الدخل السنوي",
  "注册": "التسجيل",
  "邮箱注册": "التسجيل بالبريد الإلكتروني",
  "发送": "إرسال",
  "重置密码": "إعادة تعيين كلمة المرور",
  "是否重置": "هل تريد إعادة التعيين؟",
  "已重置": "تمت إعادة التعيين",
  "名称": "الاسم",
  "注册成功": "تم التسجيل بنجاح",
  "加载中": "جارٍ التحميل",
  "您还未登录，请先登录": "لم تقم بتسجيل الدخول بعد، يرجى تسجيل الدخول أولاً",
  "退出成功": "تم تسجيل الخروج بنجاح",
  "市价": "سعر السوق",
  "挂单": "طلب معلق",
  "倍数": "الرافعة المالية",
  "止盈": "جني الأرباح",
  "止损": "وقف الخسارة",
  "买入数量": "كمية الشراء",
  "每手": "لكل عقد",
  "预估手续费": "الرسوم المقدرة",
  "预估保证金": "الهامش المقدر",
  "买入": "شراء",
  "卖出": "بيع",
  "您的订单已确认": "تم تأكيد طلبك",
  "订单页面": "صفحة الطلبات",
  "盈亏": "الأرباح والخسائر",
  "当前保证金": "الهامش الحالي",
  "订单详情": "تفاصيل الطلب",
  "保证金": "الهامش",
  "平仓": "إغلاق المركز",
  "修改": "تعديل",
  "手数": "عدد العقود",
  "暂无数据": "لا توجد بيانات",
  "历史": "التاريخ",
  "价格": "السعر",
  "请输入价格": "يرجى إدخال السعر",
  "手": "عقد",
  "搜索结果": "نتائج البحث",
  "搜索产品": "البحث عن المنتجات",
  "持仓": "المراكز المفتوحة",
  "语言": "اللغة",
  "审核中": "قيد المراجعة",
  "请上传证件照正/反面": "يرجى تحميل صورة الهوية (الوجهين)",
  "上传成功": "تم التحميل بنجاح",
  "上传失败": "فشل التحميل",
  "提交成功": "تم الإرسال بنجاح",
  "删除": "حذف",
  "删除成功": "تم الحذف بنجاح",
  "提币": "سحب العملات الرقمية",
  "缺少参数或传值错误": "نقص في المعلمات أو خطأ في القيم المرسلة",
  "操作成功": "تمت العملية بنجاح",
  "充值金额": "مبلغ الإيداع",
  "类型": "النوع",
  "状态": "الحالة",
  "时间": "الوقت",
  "单位": "الوحدة",
  "最高价": "أعلى سعر",
  "最低价": "أقل سعر",
  "开盘价": "سعر الافتتاح",
  "请注册或登录真实账号": "يرجى التسجيل أو تسجيل الدخول بحساب حقيقي",
  "没有更多了": "لا توجد بيانات إضافية",
  "提现金额": "مبلغ السحب",
  "审核通过": "تمت الموافقة",
  "审核不通过": "لم تتم الموافقة",
  "重新提交": "إعادة الإرسال",
  "止盈价": "سعر جني الأرباح",
  "止损价": "سعر وقف الخسارة",
  "总盈亏": "إجمالي الأرباح والخسائر",
  "撤单": "إلغاء الطلب",
  "成功": "نجاح",
  "请先进行实名认证": "يرجى التحقق من الهوية أولاً",
  "下单余额不足": "الرصيد غير كافٍ للطلب",
  "客服": "خدمة العملاء",
  "卖出(做空)止损价不能低于开仓价": "سعر وقف الخسارة للبيع (بيع على المكشوف) لا يمكن أن يكون أقل من سعر الفتح",
  "卖出(做多)止盈价不能高于开仓价": "سعر جني الأرباح للبيع (شراء) لا يمكن أن يكون أعلى من سعر الفتح",
  "买入(做多)止损价不能高于开仓价": "سعر وقف الخسارة للشراء لا يمكن أن يكون أعلى من سعر الفتح",
  "买入(做多)止盈价不能低于开仓价": "سعر جني الأرباح للشراء لا يمكن أن يكون أقل من سعر الفتح",
  "买入(做多)止盈价不能低于开仓价和现价": "سعر جني الأرباح للشراء لا يمكن أن يكون أقل من سعر الفتح والسعر الحالي",
  "买入(做多)止损价不能高于开仓价和现价": "سعر وقف الخسارة للشراء لا يمكن أن يكون أعلى من سعر الفتح والسعر الحالي",
  "卖出(做空)止损价不能低于开仓价和现价": "سعر وقف الخسارة للبيع (بيع على المكشوف) لا يمكن أن يكون أقل من سعر الفتح والسعر الحالي",
  "卖出(做多)止盈价不能高于开仓价和现价": "سعر جني الأرباح للبيع (شراء) لا يمكن أن يكون أعلى من سعر الفتح والسعر الحالي",
}
