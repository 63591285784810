export default {
  "首页": "Página inicial",
  "订单": "Pedidos",
  "新闻": "Notícias",
  "个人中心": "Centro pessoal",
  "模拟账户": "Conta demo",
  "可用资产": "Ativos disponíveis",
  "入金": "Depósito",
  "出金": "Saque",
  "账单详情": "Detalhes da fatura",
  "重置模拟账户": "Redefinir conta demo",
  "钱包": "Carteira",
  "实名认证": "Autenticação de identidade",
  "邀请好友": "Convidar amigos",
  "修改密码": "Alterar senha",
  "投诉邮箱": "E-mail para reclamações",
  "其它": "Outros",
  "公告": "Avisos",
  "语言": "Idioma",
  "是否退出登录": "Você deseja sair?",
  "复制": "Copiar",
  "复制成功": "Copia bem-sucedida",
  "发送验证码成功": "Código de verificação enviado com sucesso",
  "请输入密码": "Por favor, insira a senha",
  "请再次输入密码": "Por favor, insira a senha novamente",
  "复制邀请链接": "Copiar link de convite",
  "上传身份": "Faça o upload da frente e verso do seu passaporte ou documento de identidade",
  "正面": "Frente",
  "上传正面": "Carregar frente do passaporte ou documento",
  "反面": "Verso",
  "上传反面": "Carregar verso do passaporte ou documento",
  "完善信息": "Por favor, complete as informações pessoais",
  "输入姓名": "Digite seu nome",
  "身份证号": "Número de passaporte ou documento de identidade",
  "输入身份证号": "Digite seu número de passaporte ou documento",
  "收款货币": "Moeda de recebimento",
  "银行名称": "Nome do banco",
  "收款人账户": "Conta do beneficiário",
  "货币": "Moeda",
  "分店名称": "Nome da filial",
  "绑定银行卡": "Vincular cartão bancário",
  "银行地址": "Endereço do banco",
  "账号名称（假名）": "Nome da conta (nome fictício)",
  "绑定数字货币地址": "Vincular endereço de criptomoeda",
  "余额": "Saldo",
  "联系客服": "Contatar suporte",
  "行情": "Cotação",
  "其他": "Outros",
  "设置": "Configurações",
  "收款人": "Beneficiário",
  "添加": "Adicionar",
  "确认": "Confirmar",
  "取消": "Cancelar",
  "钱包地址": "Endereço da carteira",
  "请输入": "Por favor, insira",
  "退出登录": "Sair",
  "充值数量": "Quantidade de depósito",
  "提交": "Submeter",
  "银行卡": "Cartão bancário",
  "选择网络": "Escolher rede",
  "数字货币": "Criptomoeda",
  "上传验证": "Carregar para verificar",
  "提币地址": "Endereço de saque",
  "数量(USD)": "Quantidade (USD)",
  "备注": "Observações",
  "手续费": "Taxa de transação",
  "预计到账数量": "Quantidade estimada a ser recebida",
  "暂无数据": "Sem dados disponíveis",
  "入金记录": "Registro de depósitos",
  "提币记录": "Registro de saques",
  "验证码": "Código de verificação",
  "邀请码": "Código de convite",
  "提示": "Dica",
  "请输入验证码": "Por favor, insira o código de verificação",
  "姓名": "Nome",
  "邮箱登录": "Login com e-mail",
  "邮箱": "E-mail",
  "密码": "Senha",
  "新用户": "Novo usuário",
  "立即加入": "Junte-se agora",
  "忘记密码": "Esqueceu a senha?",
  "登录": "Entrar",
  "无需开户,立即创建模拟账号进行交易!": "Sem necessidade de abrir uma conta, crie uma conta demo para negociar agora!",
  "电话": "Telefone",
  "再次输入密码": "Digite a senha novamente",
  "职业": "Profissão",
  "年收入": "Renda anual",
  "注册": "Cadastrar",
  "邮箱注册": "Registro por e-mail",
  "发送": "Enviar",
  "重置密码": "Redefinir senha",
  "是否重置": "Deseja redefinir?",
  "已重置": "Redefinido",
  "名称": "Nome",
  "注册成功": "Cadastro bem-sucedido",
  "加载中": "Carregando",
  "您还未登录，请先登录": "Você ainda não está logado, faça login primeiro",
  "退出成功": "Saída bem-sucedida",
  "市价": "Preço de mercado",
  "挂单": "Ordem pendente",
  "倍数": "Multiplicador",
  "止盈": "Fechamento de lucro",
  "止损": "Fechamento de perda",
  "买入数量": "Quantidade de compra",
  "每手": "Por contrato",
  "预估手续费": "Taxa estimada",
  "预估保证金": "Margem estimada",
  "买入": "Comprar",
  "卖出": "Vender",
  "您的订单已确认": "Seu pedido foi confirmado",
  "订单页面": "Página de pedidos",
  "盈亏": "Lucro/Perda",
  "当前保证金": "Margem atual",
  "订单详情": "Detalhes do pedido",
  "保证金": "Margem",
  "平仓": "Fechar posição",
  "修改": "Modificar",
  "手数": "Quantidade de contratos",
  "暂无数据": "Sem dados disponíveis",
  "历史": "Histórico",
  "价格": "Preço",
  "请输入价格": "Digite o preço",
  "手": "Contrato",
  "搜索结果": "Resultados da busca",
  "搜索产品": "Pesquisar produtos",
  "持仓": "Posições",
  "语言": "Idioma",
  "审核中": "Em análise",
  "请上传证件照正/反面": "Por favor, carregue a frente e o verso do seu documento",
  "上传成功": "Upload bem-sucedido",
  "上传失败": "Falha no upload",
  "提交成功": "Envio bem-sucedido",
  "删除": "Excluir",
  "删除成功": "Excluído com sucesso",
  "提币": "Saque",
  "缺少参数或传值错误": "Faltando parâmetros ou valor incorreto",
  "操作成功": "Operação bem-sucedida",
  "充值金额": "Valor do depósito",
  "类型": "Tipo",
  "状态": "Status",
  "时间": "Tempo",
  "单位": "Unidade",
  "最高价": "Preço máximo",
  "最低价": "Preço mínimo",
  "开盘价": "Preço de abertura",
  "请注册或登录真实账号": "Por favor, registre-se ou faça login em uma conta real",
  "没有更多了": "Não há mais dados",
  "提现金额": "Valor de saque",
  "审核通过": "Aprovado",
  "审核不通过": "Rejeitado",
  "重新提交": "Reenviar",
  "止盈价": "Preço de fechamento de lucro",
  "止损价": "Preço de fechamento de perda",
  "总盈亏": "Lucro/Perda total",
  "撤单": "Cancelar pedido",
  "成功": "Sucesso",
  "请先进行实名认证": "Por favor, faça a autenticação de identidade primeiro",
  "下单余额不足": "Saldo insuficiente para realizar o pedido",
  "客服": "Atendimento ao cliente",
  "卖出(做空)止损价不能低于开仓价": "O preço de stop loss de venda (venda a descoberto) não pode ser inferior ao preço de abertura",
  "卖出(做多)止盈价不能高于开仓价": "O preço de take profit de venda (longa) não pode ser superior ao preço de abertura",
  "买入(做多)止损价不能高于开仓价": "O preço de stop loss de compra (longa) não pode ser superior ao preço de abertura",
  "买入(做多)止盈价不能低于开仓价": "O preço de take profit de compra (longa) não pode ser inferior ao preço de abertura",
  "买入(做多)止盈价不能低于开仓价和现价": "O preço de take profit de compra (longa) não pode ser inferior ao preço de abertura e ao preço atual",
  "买入(做多)止损价不能高于开仓价和现价": "O preço de stop loss de compra (longa) não pode ser superior ao preço de abertura e ao preço atual",
  "卖出(做空)止损价不能低于开仓价和现价": "O preço de stop loss de venda (venda a descoberto) não pode ser inferior ao preço de abertura e ao preço atual",
  "卖出(做多)止盈价不能高于开仓价和现价": "O preço de take profit de venda (longa) não pode ser superior ao preço de abertura e ao preço atual"
}
