<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    window.global = {
      // baseUrl: 'https://api.loe.xt528.com',
      baseUrl: "https://api.loetrade.cc",
      // baseWsUrl: 'wss://api.loe.xt528.com/ws'
      baseWsUrl: "wss://api.loetrade.cc/ws",
    };
    const languageMappings = {
      zh: "zh-hk",
      en: "en",
      ja: "ja",
      ar: "ar",
      fr: "fr",
      de: "de",
      es: "es",
      it: "it",
      pt: "pt",
      id: "id",
      th: "th",
      tr: "tr",
      kr: "kr",
      vi: "vi",
      ru: "ru",
      pt: "pt",
    };
    if (!localStorage.getItem("lang")) {
      const la = navigator.language.toLowerCase();
      if (languageMappings[la]) {
        localStorage.setItem("lang", la);
      } else {
        localStorage.setItem("lang", "en");
      }
    }
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  background: #fff;
}
::-webkit-scrollbar {
  display: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.red {
  color: #f23c48;
}
.blue {
  color: #0166fc;
}
</style>
