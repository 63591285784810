export default {
  "首页": "मुखपृष्ठ",
  "订单": "आदेश",
  "新闻": "समाचार",
  "个人中心": "व्यक्तिगत केंद्र",
  "模拟账户": "डेमो खाता",
  "可用资产": "उपलब्ध संपत्ति",
  "入金": "जमा",
  "出金": "निकासी",
  "账单详情": "बिल विवरण",
  "重置模拟账户": "डेमो खाता रीसेट करें",
  "钱包": "वॉलेट",
  "实名认证": "पहचान सत्यापन",
  "邀请好友": "दोस्तों को आमंत्रित करें",
  "修改密码": "पासवर्ड बदलें",
  "投诉邮箱": "शिकायत ईमेल",
  "其它": "अन्य",
  "公告": "घोषणाएँ",
  "语言": "भाषा",
  "是否退出登录": "क्या आप लॉगआउट करना चाहते हैं?",
  "复制": "कॉपी करें",
  "复制成功": "कॉपी सफल",
  "发送验证码成功": "वेरिफिकेशन कोड सफलतापूर्वक भेजा गया",
  "请输入密码": "कृपया अपना पासवर्ड दर्ज करें",
  "请再次输入密码": "कृपया पासवर्ड फिर से दर्ज करें",
  "复制邀请链接": "आमंत्रण लिंक कॉपी करें",
  "上传身份": "कृपया अपनी पहचान पत्र का अग्रभाग और पिछला भाग अपलोड करें",
  "正面": "मुख्य भाग",
  "上传正面": "पहचान पत्र का अग्रभाग अपलोड करें",
  "反面": "पिछला भाग",
  "上传反面": "पहचान पत्र का पिछला भाग अपलोड करें",
  "完善信息": "कृपया अपनी व्यक्तिगत जानकारी पूर्ण करें",
  "输入姓名": "कृपया अपना नाम दर्ज करें",
  "身份证号": "पहचान संख्या",
  "输入身份证号": "कृपया अपनी पहचान संख्या दर्ज करें",
  "收款货币": "प्राप्ति मुद्रा",
  "银行名称": "बैंक का नाम",
  "收款人账户": "प्राप्तकर्ता खाता",
  "货币": "मुद्रा",
  "分店名称": "शाखा का नाम",
  "绑定银行卡": "बैंक कार्ड जोड़ें",
  "银行地址": "बैंक का पता",
  "账号名称（假名）": "खाता नाम (उपनाम)",
  "绑定数字货币地址": "क्रिप्टोकरेंसी पता जोड़ें",
  "余额": "बैलेंस",
  "联系客服": "ग्राहक सेवा से संपर्क करें",
  "行情": "बाजार",
  "其他": "अन्य",
  "设置": "सेटिंग्स",
  "收款人": "प्राप्तकर्ता",
  "添加": "जोड़ें",
  "确认": "पुष्टि करें",
  "取消": "रद्द करें",
  "钱包地址": "वॉलेट पता",
  "请输入": "कृपया दर्ज करें",
  "退出登录": "लॉगआउट करें",
  "充值数量": "जमा राशि",
  "提交": "जमा करें",
  "银行卡": "बैंक कार्ड",
  "选择网络": "नेटवर्क चयन करें",
  "数字货币": "क्रिप्टोकरेंसी",
  "上传验证": "सत्यापन अपलोड करें",
  "提币地址": "निकासी पता",
  "数量(USD)": "राशि (USD)",
  "备注": "टिप्पणी",
  "手续费": "फीस",
  "预计到账数量": "अनुमानित राशि",
  "暂无数据": "कोई डेटा नहीं",
  "入金记录": "जमा रिकॉर्ड",
  "提币记录": "निकासी रिकॉर्ड",
  "验证码": "वेरिफिकेशन कोड",
  "邀请码": "आमंत्रण कोड",
  "提示": "सूचना",
  "请输入验证码": "कृपया वेरिफिकेशन कोड दर्ज करें",
  "姓名": "नाम",
  "邮箱登录": "ईमेल लॉगिन",
  "邮箱": "ईमेल",
  "密码": "पासवर्ड",
  "新用户": "नया उपयोगकर्ता",
  "立即加入": "अभी शामिल हों",
  "忘记密码": "पासवर्ड भूल गए",
  "登录": "लॉगिन करें",
  "无需开户,立即创建模拟账号进行交易!": "खाता खोलने की आवश्यकता नहीं है, तुरंत डेमो खाता बनाकर व्यापार शुरू करें!",
  "电话": "फोन",
  "再次输入密码": "पासवर्ड फिर से दर्ज करें",
  "职业": "पेशा",
  "年收入": "वार्षिक आय",
  "注册": "रजिस्टर करें",
  "邮箱注册": "ईमेल से रजिस्टर करें",
  "发送": "भेजें",
  "重置密码": "पासवर्ड रीसेट करें",
  "是否重置": "क्या आप रीसेट करना चाहते हैं?",
  "已重置": "रीसेट हो चुका है",
  "名称": "नाम",
  "注册成功": "रजिस्ट्रेशन सफल",
  "加载中": "लोड हो रहा है",
  "您还未登录，请先登录": "आप अभी तक लॉग इन नहीं हैं, कृपया पहले लॉग इन करें",
  "退出成功": "लॉगआउट सफल",
  "市价": "मार्केट प्राइस",
  "挂单": "लंबित आदेश",
  "倍数": "गुणा",
  "止盈": "लाभ का प्रबंधन",
  "止损": "हानि का प्रबंधन",
  "买入数量": "खरीदी की मात्रा",
  "每手": "प्रति हाथ",
  "预估手续费": "अनुमानित शुल्क",
  "预估保证金": "अनुमानित मार्जिन",
  "买入": "खरीदें",
  "卖出": "बेचें",
  "您的订单已确认": "आपका आदेश पुष्टि हो चुका है",
  "订单页面": "आदेश पृष्ठ",
  "盈亏": "लाभ और हानि",
  "当前保证金": "वर्तमान मार्जिन",
  "订单详情": "आदेश विवरण",
  "保证金": "मार्जिन",
  "平仓": "पोजीशन क्लोज़ करें",
  "修改": "संशोधित करें",
  "手数": "लॉट्स",
  "暂无数据": "कोई डेटा नहीं",
  "历史": "इतिहास",
  "价格": "कीमत",
  "请输入价格": "कृपया मूल्य दर्ज करें",
  "手": "हाथ",
  "搜索结果": "खोज परिणाम",
  "搜索产品": "उत्पाद खोजें",
  "持仓": "पोजीशन",
  "语言": "भाषा",
  "审核中": "समीक्षा में",
  "请上传证件照正/反面": "कृपया अपनी पहचान पत्र का अग्रभाग और पिछला भाग अपलोड करें",
  "上传成功": "सफलतापूर्वक अपलोड किया गया",
  "上传失败": "अपलोड विफल",
  "提交成功": "सफलतापूर्वक जमा किया गया",
  "删除": "हटाएं",
  "删除成功": "सफलतापूर्वक हटाया गया",
  "提币": "निकासी",
  "缺少参数或传值错误": "पैरामीटर गायब या मान त्रुटि",
  "操作成功": "ऑपरेशन सफल",
  "充值金额": "जमा राशि",
  "类型": "प्रकार",
  "状态": "स्थिति",
  "时间": "समय",
  "单位": "यूनिट",
  "最高价": "उच्चतम मूल्य",
  "最低价": "न्यूनतम मूल्य",
  "开盘价": "खुलने की कीमत",
  "请注册或登录真实账号": "कृपया असली खाते में पंजीकरण या लॉगिन करें",
  "没有更多了": "कोई और डेटा नहीं है",
  "提现金额": "निकासी राशि",
  "审核通过": "समीक्षा स्वीकृत",
  "审核不通过": "समीक्षा अस्वीकृत",
  "重新提交": "फिर से जमा करें",
  "止盈价": "लाभ प्रबंधन मूल्य",
  "止损价": "हानि प्रबंधन मूल्य",
  "总盈亏": "कुल लाभ/हानि",
  "撤单": "आदेश रद्द करें",
  "成功": "सफल",
  "请先进行实名认证": "कृपया पहले पहचान सत्यापन करें",
  "下单余额不足": "आदेश देने के लिए पर्याप्त बैलेंस नहीं है",
  "客服": "ग्राहक सेवा",
  "卖出(做空)止损价不能低于开仓价": "बेचने (शॉर्ट) के लिए स्टॉप लॉस मूल्य खोलने की कीमत से कम नहीं हो सकता",
  "卖出(做多)止盈价不能高于开仓价": "बेचने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत से अधिक नहीं हो सकता",
  "买入(做多)止损价不能高于开仓价": "खरीदने (लॉन्ग) के लिए स्टॉप लॉस मूल्य खोलने की कीमत से अधिक नहीं हो सकता",
  "买入(做多)止盈价不能低于开仓价": "खरीदने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत से कम नहीं हो सकता",
  "买入(做多)止盈价不能低于开仓价和现价": "खरीदने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत और वर्तमान कीमत से कम नहीं हो सकता",
  "买入(做多)止损价不能高于开仓价和现价": "खरीदने (लॉन्ग) के लिए स्टॉप लॉस मूल्य खोलने की कीमत और वर्तमान कीमत से अधिक नहीं हो सकता",
  "卖出(做空)止损价不能低于开仓价和现价": "बेचने (शॉर्ट) के लिए स्टॉप लॉस मूल्य खोलने की कीमत और वर्तमान कीमत से कम नहीं हो सकता",
  "卖出(做多)止盈价不能高于开仓价和现价": "बेचने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत और वर्तमान कीमत से अधिक नहीं हो सकता"
}
