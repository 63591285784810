export default {
  "首页": "Trang Chủ",
  "订单": "Đơn Hàng",
  "新闻": "Tin Tức",
  "个人中心": "Trung Tâm Cá Nhân",
  "模拟账户": "Tài Khoản Mô Phỏng",
  "可用资产": "Tài Sản Sử Dụng Được",
  "入金": "Nạp Tiền",
  "出金": "Rút Tiền",
  "账单详情": "Chi Tiết Hóa Đơn",
  "重置模拟账户": "Đặt Lại Tài Khoản Mô Phỏng",
  "钱包": "Ví",
  "实名认证": "Xác Thực Danh Tính",
  "邀请好友": "Mời Bạn Bè",
  "修改密码": "Thay Đổi Mật Khẩu",
  "投诉邮箱": "Email Khiếu Nại",
  "其它": "Khác",
  "公告": "Thông Báo",
  "语言": "Ngôn Ngữ",
  "是否退出登录": "Bạn có chắc chắn muốn đăng xuất?",
  "复制": "Sao Chép",
  "复制成功": "Sao Chép Thành Công",
  "发送验证码成功": "Gửi Mã Xác Thực Thành Công",
  "请输入密码": "Vui lòng nhập mật khẩu",
  "请再次输入密码": "Vui lòng nhập lại mật khẩu",
  "复制邀请链接": "Sao Chép Liên Kết Mời",
  "上传身份": "Vui lòng tải lên mặt trước và mặt sau của hộ chiếu/CMND",
  "正面": "Mặt Trước",
  "上传正面": "Tải lên mặt trước hộ chiếu/CMND",
  "反面": "Mặt Sau",
  "上传反面": "Tải lên mặt sau hộ chiếu/CMND",
  "完善信息": "Vui lòng hoàn thiện thông tin cá nhân",
  "输入姓名": "Vui lòng nhập tên của bạn",
  "身份证号": "Số hộ chiếu/CMND",
  "输入身份证号": "Vui lòng nhập số hộ chiếu/CMND của bạn",
  "收款货币": "Tiền Tệ Nhận",
  "银行名称": "Tên Ngân Hàng",
  "收款人账户": "Tài Khoản Người Nhận",
  "货币": "Tiền Tệ",
  "分店名称": "Tên Chi Nhánh",
  "绑定银行卡": "Liên Kết Thẻ Ngân Hàng",
  "银行地址": "Địa Chỉ Ngân Hàng",
  "账号名称（假名）": "Tên Tài Khoản (Tên Giả)",
  "绑定数字货币地址": "Liên Kết Địa Chỉ Tiền Điện Tử",
  "余额": "Số Dư",
  "联系客服": "Liên Hệ Hỗ Trợ Khách Hàng",
  "行情": "Thị Trường",
  "其他": "Khác",
  "设置": "Cài Đặt",
  "收款人": "Người Nhận",
  "添加": "Thêm",
  "确认": "Xác Nhận",
  "取消": "Hủy",
  "钱包地址": "Địa Chỉ Ví",
  "请输入": "Vui lòng nhập",
  "退出登录": "Đăng Xuất",
  "充值数量": "Số Tiền Nạp",
  "提交": "Gửi",
  "银行卡": "Thẻ Ngân Hàng",
  "选择网络": "Chọn Mạng",
  "数字货币": "Tiền Điện Tử",
  "上传验证": "Tải Lên Xác Thực",
  "提币地址": "Địa Chỉ Rút Tiền",
  "数量(USD)": "Số Lượng (USD)",
  "备注": "Ghi Chú",
  "手续费": "Phí Giao Dịch",
  "预计到账数量": "Số Tiền Dự Kiến Nhận Được",
  "暂无数据": "Chưa có dữ liệu",
  "入金记录": "Lịch Sử Nạp Tiền",
  "提币记录": "Lịch Sử Rút Tiền",
  "验证码": "Mã Xác Thực",
  "邀请码": "Mã Mời",
  "提示": "Lưu Ý",
  "请输入验证码": "Vui lòng nhập mã xác thực",
  "姓名": "Tên",
  "邮箱登录": "Đăng Nhập Qua Email",
  "邮箱": "Email",
  "密码": "Mật Khẩu",
  "新用户": "Người Dùng Mới",
  "立即加入": "Tham Gia Ngay",
  "忘记密码": "Quên Mật Khẩu",
  "登录": "Đăng Nhập",
  "无需开户,立即创建模拟账号进行交易!": "Không cần mở tài khoản, tạo tài khoản mô phỏng ngay để giao dịch!",
  "电话": "Điện Thoại",
  "再次输入密码": "Nhập lại mật khẩu",
  "职业": "Nghề Nghiệp",
  "年收入": "Thu Nhập Hàng Năm",
  "注册": "Đăng Ký",
  "邮箱注册": "Đăng Ký Email",
  "发送": "Gửi",
  "重置密码": "Đặt Lại Mật Khẩu",
  "是否重置": "Bạn có chắc chắn muốn đặt lại mật khẩu?",
  "已重置": "Đã Đặt Lại",
  "名称": "Tên",
  "注册成功": "Đăng Ký Thành Công",
  "加载中": "Đang Tải",
  "您还未登录，请先登录": "Bạn chưa đăng nhập, vui lòng đăng nhập trước",
  "退出成功": "Đăng Xuất Thành Công",
  "市价": "Giá Thị Trường",
  "挂单": "Đặt Lệnh",
  "倍数": "Số Lần",
  "止盈": "Chốt Lãi",
  "止损": "Cắt Lỗ",
  "买入数量": "Số Lượng Mua",
  "每手": "Mỗi Lệnh",
  "预估手续费": "Phí Giao Dịch Dự Kiến",
  "预估保证金": "Ký Quỹ Dự Kiến",
  "买入": "Mua",
  "卖出": "Bán",
  "您的订单已确认": "Đơn Hàng Của Bạn Đã Được Xác Nhận",
  "订单页面": "Trang Đơn Hàng",
  "盈亏": "Lãi Lỗ",
  "当前保证金": "Ký Quỹ Hiện Tại",
  "订单详情": "Chi Tiết Đơn Hàng",
  "保证金": "Ký Quỹ",
  "平仓": "Đóng Lệnh",
  "修改": "Sửa",
  "手数": "Số Lệnh",
  "暂无数据": "Chưa Có Dữ Liệu",
  "历史": "Lịch Sử",
  "价格": "Giá",
  "请输入价格": "Vui lòng nhập giá",
  "手": "Lệnh",
  "搜索结果": "Kết Quả Tìm Kiếm",
  "搜索产品": "Tìm Sản Phẩm",
  "持仓": "Vị Thế",
  "语言": "Ngôn Ngữ",
  "审核中": "Đang Xem Xét",
  "请上传证件照正/反面": "Vui lòng tải lên mặt trước và mặt sau của chứng minh thư",
  "上传成功": "Tải Lên Thành Công",
  "上传失败": "Tải Lên Thất Bại",
  "提交成功": "Gửi Thành Công",
  "删除": "Xóa",
  "删除成功": "Xóa Thành Công",
  "提币": "Rút Tiền Điện Tử",
  "缺少参数或传值错误": "Thiếu Tham Số Hoặc Lỗi Truyền Giá Trị",
  "操作成功": "Thao Tác Thành Công",
  "充值金额": "Số Tiền Nạp",
  "类型": "Loại",
  "状态": "Trạng Thái",
  "时间": "Thời Gian",
  "单位": "Đơn Vị",
  "最高价": "Giá Cao Nhất",
  "最低价": "Giá Thấp Nhất",
  "开盘价": "Giá Mở Cửa",
  "请注册或登录真实账号": "Vui lòng đăng ký hoặc đăng nhập vào tài khoản thật",
  "没有更多了": "Không Còn Dữ Liệu",
  "提现金额": "Số Tiền Rút",
  "审核通过": "Duyệt Thành Công",
  "审核不通过": "Duyệt Thất Bại",
  "重新提交": "Gửi Lại",
  "止盈价": "Giá Chốt Lãi",
  "止损价": "Giá Cắt Lỗ",
  "总盈亏": "Tổng Lãi Lỗ",
  "撤单": "Hủy Lệnh",
  "成功": "Thành Công",
  "请先进行实名认证": "Vui lòng thực hiện xác thực danh tính trước",
  "下单余额不足": "Số Dư Không Đủ Để Đặt Lệnh",
  "客服": "Hỗ Trợ Khách Hàng",
  "卖出(做空)止损价不能低于开仓价": "Giá Cắt Lỗ Bán (Short) Không Thể Thấp Hơn Giá Mở Lệnh",
  "卖出(做多)止盈价不能高于开仓价": "Giá Chốt Lãi Bán (Long) Không Thể Cao Hơn Giá Mở Lệnh",
  "买入(做多)止损价不能高于开仓价": "Giá Cắt Lỗ Mua (Long) Không Thể Cao Hơn Giá Mở Lệnh",
  "买入(做多)止盈价不能低于开仓价": "Giá Chốt Lãi Mua (Long) Không Thể Thấp Hơn Giá Mở Lệnh",
  "买入(做多)止盈价不能低于开仓价和现价": "Giá Chốt Lãi Mua (Long) Không Thể Thấp Hơn Giá Mở Lệnh và Giá Hiện Tại",
  "买入(做多)止损价不能高于开仓价和现价": "Giá Cắt Lỗ Mua (Long) Không Thể Cao Hơn Giá Mở Lệnh và Giá Hiện Tại",
  "卖出(做空)止损价不能低于开仓价和现价": "Giá Cắt Lỗ Bán (Short) Không Thể Thấp Hơn Giá Mở Lệnh và Giá Hiện Tại",
  "卖出(做多)止盈价不能高于开仓价和现价": "Giá Chốt Lãi Bán (Long) Không Thể Cao Hơn Giá Mở Lệnh và Giá Hiện Tại"
}
