export default {
  "首页": "Главная",
  "订单": "Заказы",
  "新闻": "Новости",
  "个人中心": "Личный кабинет",
  "模拟账户": "Демо-аккаунт",
  "可用资产": "Доступные активы",
  "入金": "Пополнение",
  "出金": "Вывод",
  "账单详情": "Детали счета",
  "重置模拟账户": "Сбросить демо-аккаунт",
  "钱包": "Кошелек",
  "实名认证": "Подтверждение личности",
  "邀请好友": "Пригласить друга",
  "修改密码": "Изменить пароль",
  "投诉邮箱": "Электронная почта для жалоб",
  "其它": "Прочее",
  "公告": "Объявления",
  "语言": "Язык",
  "是否退出登录": "Вы уверены, что хотите выйти?",
  "复制": "Копировать",
  "复制成功": "Копирование успешно",
  "发送验证码成功": "Код подтверждения отправлен успешно",
  "请输入密码": "Пожалуйста, введите пароль",
  "请再次输入密码": "Пожалуйста, введите пароль еще раз",
  "复制邀请链接": "Копировать ссылку для приглашения",
  "上传身份": "Пожалуйста, загрузите лицевую и оборотную сторону вашего паспорта или удостоверения личности",
  "正面": "Лицевая сторона",
  "上传正面": "Загрузить лицевую сторону паспорта/документа",
  "反面": "Обратная сторона",
  "上传反面": "Загрузить оборотную сторону паспорта/документа",
  "完善信息": "Пожалуйста, заполните личные данные",
  "输入姓名": "Введите ваше имя",
  "身份证号": "Номер паспорта/удостоверения личности",
  "输入身份证号": "Введите номер паспорта/удостоверения личности",
  "收款货币": "Валюта получения",
  "银行名称": "Название банка",
  "收款人账户": "Счет получателя",
  "货币": "Валюта",
  "分店名称": "Название филиала",
  "绑定银行卡": "Привязать банковскую карту",
  "银行地址": "Адрес банка",
  "账号名称（假名）": "Название аккаунта (псевдоним)",
  "绑定数字货币地址": "Привязать адрес криптовалюты",
  "余额": "Баланс",
  "联系客服": "Связаться с поддержкой",
  "行情": "Котировки",
  "其他": "Прочее",
  "设置": "Настройки",
  "收款人": "Получатель",
  "添加": "Добавить",
  "确认": "Подтвердить",
  "取消": "Отменить",
  "钱包地址": "Адрес кошелька",
  "请输入": "Пожалуйста, введите",
  "退出登录": "Выйти",
  "充值数量": "Сумма пополнения",
  "提交": "Отправить",
  "银行卡": "Банковская карта",
  "选择网络": "Выберите сеть",
  "数字货币": "Цифровая валюта",
  "上传验证": "Загрузить для проверки",
  "提币地址": "Адрес вывода",
  "数量(USD)": "Количество (USD)",
  "备注": "Примечания",
  "手续费": "Комиссия",
  "预计到账数量": "Ожидаемая сумма поступления",
  "暂无数据": "Нет данных",
  "入金记录": "Записи пополнений",
  "提币记录": "Записи выводов",
  "验证码": "Код подтверждения",
  "邀请码": "Пригласительный код",
  "提示": "Подсказка",
  "请输入验证码": "Пожалуйста, введите код подтверждения",
  "姓名": "Имя",
  "邮箱登录": "Вход через почту",
  "邮箱": "Электронная почта",
  "密码": "Пароль",
  "新用户": "Новый пользователь",
  "立即加入": "Присоединиться сейчас",
  "忘记密码": "Забыли пароль?",
  "登录": "Войти",
  "无需开户,立即创建模拟账号进行交易!": "Нет необходимости открывать счет, создайте демо-аккаунт и начните торговать!",
  "电话": "Телефон",
  "再次输入密码": "Введите пароль еще раз",
  "职业": "Профессия",
  "年收入": "Годовой доход",
  "注册": "Зарегистрироваться",
  "邮箱注册": "Регистрация через почту",
  "发送": "Отправить",
  "重置密码": "Сбросить пароль",
  "是否重置": "Вы уверены, что хотите сбросить?",
  "已重置": "Сброшено",
  "名称": "Название",
  "注册成功": "Регистрация успешна",
  "加载中": "Загрузка",
  "您还未登录，请先登录": "Вы еще не вошли в систему, пожалуйста, войдите",
  "退出成功": "Выход успешен",
  "市价": "Рыночная цена",
  "挂单": "Отложенный ордер",
  "倍数": "Множитель",
  "止盈": "Тейк-профит",
  "止损": "Стоп-лосс",
  "买入数量": "Количество для покупки",
  "每手": "На контракт",
  "预估手续费": "Ожидаемая комиссия",
  "预估保证金": "Ожидаемая маржа",
  "买入": "Купить",
  "卖出": "Продать",
  "您的订单已确认": "Ваш заказ подтвержден",
  "订单页面": "Страница заказов",
  "盈亏": "Прибыль/убыток",
  "当前保证金": "Текущая маржа",
  "订单详情": "Детали заказа",
  "保证金": "Маржа",
  "平仓": "Закрытие позиции",
  "修改": "Изменить",
  "手数": "Количество контрактов",
  "暂无数据": "Нет данных",
  "历史": "История",
  "价格": "Цена",
  "请输入价格": "Введите цену",
  "手": "Контракт",
  "搜索结果": "Результаты поиска",
  "搜索产品": "Поиск продуктов",
  "持仓": "Открытые позиции",
  "语言": "Язык",
  "审核中": "На проверке",
  "请上传证件照正/反面": "Пожалуйста, загрузите фото лицевой и оборотной стороны документа",
  "上传成功": "Загрузка успешна",
  "上传失败": "Загрузка не удалась",
  "提交成功": "Отправлено успешно",
  "删除": "Удалить",
  "删除成功": "Удалено успешно",
  "提币": "Вывод криптовалюты",
  "缺少参数或传值错误": "Отсутствуют параметры или ошибка передачи значений",
  "操作成功": "Операция успешна",
  "充值金额": "Сумма пополнения",
  "类型": "Тип",
  "状态": "Статус",
  "时间": "Время",
  "单位": "Единица",
  "最高价": "Максимальная цена",
  "最低价": "Минимальная цена",
  "开盘价": "Цена открытия",
  "请注册或登录真实账号": "Пожалуйста, зарегистрируйтесь или войдите в реальный аккаунт",
  "没有更多了": "Больше нет данных",
  "提现金额": "Сумма вывода",
  "审核通过": "Проверка пройдена",
  "审核不通过": "Проверка не пройдена",
  "重新提交": "Отправить заново",
  "止盈价": "Цена тейк-профита",
  "止损价": "Цена стоп-лосса",
  "总盈亏": "Общий результат",
  "撤单": "Отменить заказ",
  "成功": "Успешно",
  "请先进行实名认证": "Пожалуйста, пройдите процедуру подтверждения личности",
  "下单余额不足": "Недостаточно средств для оформления заказа",
  "客服": "Служба поддержки",
  "卖出(做空)止损价不能低于开仓价": "Цена стоп-лосса при продаже (короткая позиция) не может быть ниже цены открытия",
  "卖出(做多)止盈价不能高于开仓价": "Цена тейк-профита при продаже (длинная позиция) не может быть выше цены открытия",
  "买入(做多)止损价不能高于开仓价": "Цена стоп-лосса при покупке (длинная позиция) не может быть выше цены открытия",
  "买入(做多)止盈价不能低于开仓价": "Цена тейк-профита при покупке (длинная позиция) не может быть ниже цены открытия",
  "买入(做多)止盈价不能低于开仓价和现价": "Цена тейк-профита при покупке (длинная позиция) не может быть ниже цены открытия и текущей цены",
  "买入(做多)止损价不能高于开仓价和现价": "Цена стоп-лосса при покупке (длинная позиция) не может быть выше цены открытия и текущей цены",
  "卖出(做空)止损价不能低于开仓价和现价": "Цена стоп-лосса при продаже (короткая позиция) не может быть ниже цены открытия и текущей цены",
  "卖出(做多)止盈价不能高于开仓价和现价": "Цена тейк-профита при продаже (длинная позиция) не может быть выше цены открытия и текущей цены"
}
