export default {
  "首页": "Inicio",
  "订单": "Órdenes",
  "新闻": "Noticias",
  "个人中心": "Perfil",
  "模拟账户": "Cuenta demo",
  "可用资产": "Activos disponibles",
  "入金": "Depósito",
  "出金": "Retiro",
  "账单详情": "Detalles de factura",
  "重置模拟账户": "Restablecer cuenta demo",
  "钱包": "Cartera",
  "实名认证": "Verificación de identidad",
  "邀请好友": "Invitar amigos",
  "修改密码": "Cambiar contraseña",
  "投诉邮箱": "Correo electrónico de quejas",
  "其它": "Otros",
  "公告": "Anuncios",
  "语言": "Idioma",
  "是否退出登录": "¿Deseas cerrar sesión?",
  "复制": "Copiar",
  "复制成功": "Copiado con éxito",
  "发送验证码成功": "Código de verificación enviado con éxito",
  "请输入密码": "Por favor, ingresa tu contraseña",
  "请再次输入密码": "Por favor, ingresa nuevamente tu contraseña",
  "复制邀请链接": "Copiar enlace de invitación",
  "上传身份": "Por favor, sube la parte frontal y posterior de tu identificación",
  "正面": "Frontal",
  "上传正面": "Sube el frente de la identificación",
  "反面": "Trasera",
  "上传反面": "Sube la parte trasera de la identificación",
  "完善信息": "Por favor, completa la información personal",
  "输入姓名": "Por favor, ingresa tu nombre",
  "身份证号": "Número de identificación",
  "输入身份证号": "Por favor, ingresa tu número de identificación",
  "收款货币": "Moneda de pago",
  "银行名称": "Nombre del banco",
  "收款人账户": "Cuenta del receptor",
  "货币": "Moneda",
  "分店名称": "Nombre de la sucursal",
  "绑定银行卡": "Vincular tarjeta bancaria",
  "银行地址": "Dirección del banco",
  "账号名称（假名）": "Nombre de cuenta (seudónimo)",
  "绑定数字货币地址": "Vincular dirección de criptomoneda",
  "余额": "Saldo",
  "联系客服": "Contactar con soporte",
  "行情": "Mercado",
  "其他": "Otro",
  "设置": "Configuración",
  "收款人": "Receptor",
  "添加": "Agregar",
  "确认": "Confirmar",
  "取消": "Cancelar",
  "钱包地址": "Dirección de cartera",
  "请输入": "Por favor, ingresa",
  "退出登录": "Cerrar sesión",
  "充值数量": "Monto de depósito",
  "提交": "Enviar",
  "银行卡": "Tarjeta bancaria",
  "选择网络": "Seleccionar red",
  "数字货币": "Criptomoneda",
  "上传验证": "Subir verificación",
  "提币地址": "Dirección de retiro",
  "数量(USD)": "Cantidad (USD)",
  "备注": "Comentarios",
  "手续费": "Tarifa",
  "预计到账数量": "Cantidad estimada",
  "暂无数据": "No hay datos disponibles",
  "入金记录": "Registros de depósito",
  "提币记录": "Registros de retiro",
  "验证码": "Código de verificación",
  "邀请码": "Código de invitación",
  "提示": "Sugerencia",
  "请输入验证码": "Por favor, ingresa el código de verificación",
  "姓名": "Nombre",
  "邮箱登录": "Iniciar sesión con correo electrónico",
  "邮箱": "Correo electrónico",
  "密码": "Contraseña",
  "新用户": "Nuevo usuario",
  "立即加入": "Únete ahora",
  "忘记密码": "¿Olvidaste tu contraseña?",
  "登录": "Iniciar sesión",
  "无需开户,立即创建模拟账号进行交易!": "No es necesario abrir una cuenta, ¡crea una cuenta demo y comienza a operar ahora!",
  "电话": "Teléfono",
  "再次输入密码": "Vuelve a ingresar tu contraseña",
  "职业": "Profesión",
  "年收入": "Ingreso anual",
  "注册": "Registrarse",
  "邮箱注册": "Registro por correo electrónico",
  "发送": "Enviar",
  "重置密码": "Restablecer contraseña",
  "是否重置": "¿Deseas restablecer?",
  "已重置": "Restablecido",
  "名称": "Nombre",
  "注册成功": "Registro exitoso",
  "加载中": "Cargando...",
  "您还未登录，请先登录": "No has iniciado sesión, por favor inicia sesión primero",
  "退出成功": "Sesión cerrada con éxito",
  "市价": "Precio de mercado",
  "挂单": "Orden pendiente",
  "倍数": "Multiplicador",
  "止盈": "Tomar ganancias",
  "止损": "Detener pérdidas",
  "买入数量": "Cantidad de compra",
  "每手": "Por lote",
  "预估手续费": "Tarifa estimada",
  "预估保证金": "Margen estimado",
  "买入": "Comprar",
  "卖出": "Vender",
  "您的订单已确认": "Tu orden ha sido confirmada",
  "订单页面": "Página de órdenes",
  "盈亏": "Ganancia/Pérdida",
  "当前保证金": "Margen actual",
  "订单详情": "Detalles de la orden",
  "保证金": "Margen",
  "平仓": "Cerrar posición",
  "修改": "Modificar",
  "手数": "Lotes",
  "暂无数据": "No hay datos disponibles",
  "历史": "Historial",
  "价格": "Precio",
  "请输入价格": "Por favor, ingresa un precio",
  "手": "Lote",
  "搜索结果": "Resultados de búsqueda",
  "搜索产品": "Buscar productos",
  "持仓": "Posiciones",
  "语言": "Idioma",
  "审核中": "En revisión",
  "请上传证件照正/反面": "Por favor, sube las imágenes frontal y trasera del documento",
  "上传成功": "Subido con éxito",
  "上传失败": "Subida fallida",
  "提交成功": "Enviado con éxito",
  "删除": "Eliminar",
  "删除成功": "Eliminado con éxito",
  "提币": "Retiro de criptomonedas",
  "缺少参数或传值错误": "Faltan parámetros o valores incorrectos",
  "操作成功": "Operación exitosa",
  "充值金额": "Monto de depósito",
  "类型": "Tipo",
  "状态": "Estado",
  "时间": "Hora",
  "单位": "Unidad",
  "最高价": "Precio más alto",
  "最低价": "Precio más bajo",
  "开盘价": "Precio de apertura",
  "请注册或登录真实账号": "Por favor, regístrate o inicia sesión en una cuenta real",
  "没有更多了": "No hay más datos",
  "提现金额": "Monto de retiro",
  "审核通过": "Aprobado",
  "审核不通过": "No aprobado",
  "重新提交": "Volver a enviar",
  "止盈价": "Precio de toma de ganancias",
  "止损价": "Precio de detención de pérdidas",
  "总盈亏": "Ganancia/Pérdida total",
  "撤单": "Cancelar orden",
  "成功": "Éxito",
  "请先进行实名认证": "Por favor, verifica tu identidad primero",
  "下单余额不足": "Saldo insuficiente para realizar la orden",
  "客服": "Atención al cliente",
  "卖出(做空)止损价不能低于开仓价": "El precio de stop-loss para la venta (corto) no puede ser menor que el precio de apertura",
  "卖出(做多)止盈价不能高于开仓价": "El precio de toma de ganancias para la venta (largo) no puede ser mayor que el precio de apertura",
  "买入(做多)止损价不能高于开仓价": "El precio de stop-loss para la compra (largo) no puede ser mayor que el precio de apertura",
  "买入(做多)止盈价不能低于开仓价": "El precio de toma de ganancias para la compra (largo) no puede ser menor que el precio de apertura",
  "买入(做多)止盈价不能低于开仓价和现价": "El precio de toma de ganancias para la compra (largo) no puede ser menor que el precio de apertura y el precio actual",
  "买入(做多)止损价不能高于开仓价和现价": "El precio de stop-loss para la compra (largo) no puede ser mayor que el precio de apertura y el precio actual",
  "卖出(做空)止损价不能低于开仓价和现价": "El precio de stop-loss para la venta (corto) no puede ser menor que el precio de apertura y el precio actual",
  "卖出(做多)止盈价不能高于开仓价和现价": "El precio de toma de ganancias para la venta (largo) no puede ser mayor que el precio de apertura y el precio actual",
}
